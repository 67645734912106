<template>
  <Layout>
    <div class="bsat__container webinar">
      <router-link
        text
        to="/training-in-bioeconomy-sectors"
        class="bsat__btn dark-text px-0 font-weight-bold"
      >
        <v-icon left color="dark">mdi-keyboard-backspace</v-icon>
        {{ $t("backToWebinars") }}
      </router-link>
      <v-img
        contain
        class="img my-sm-15"
        src="@/assets/icons/webinars/foodAndFeed.jpg"
      ></v-img>
      <div class="bsat__title">{{ $t("title") }}</div>
      <div class="bsat__paragraph">{{ $t("intro") }}</div>

      <v-list class="py-0">
        <Webinar
          v-for="(webinar, i) in webinars"
          :key="i"
          v-bind="{ ...webinar }"
        ></Webinar>
      </v-list>
    </div>
  </Layout>
</template>

<i18n>
{
	"en": {
		"backToWebinars": "Back",
		"intro": "Consult the training videos and materials about food and feed. In a set of eight thematic training webinars, experts from the POWER4BIO consortium give insights about the current status of food and feed at EU level, innovative practices and keys for successful business cases, value chains, sustainability, funding opportunities and supporting policies. They show examples and explained drivers and hurdles for valorisation of biomass for food and feed. Such valorisation on one hand contributes to fulfilling increasing demand for food and feed, and on the other hand may contribute to added value creation from e.g. side streams. Smart solutions will be necessary to satisfy the demands for food, feed and non-food applications. Also drivers from policy and the potential impact for practical opportunities as well as practical examples are presented. ",
		"title": "Training on Food"
	}
}
</i18n>

<script>
import Layout from "@/layouts/default";
import Webinar from "@/components/Webinar";
export default {
  name: "BioChemicals",
  metaInfo: {
    title: "Training in Biochemocals"
  },
  components: {
    Layout,
    Webinar
  },
  data() {
    return {
      webinars: [
        {
          title:
            "Introduction to biomass valorisation for food and feed in the global picture",
          speakers: ["Jan Broeze – Wageningen Food & Biobased Research"],
          // video:"https://www.youtube.com/watch?v=K-DQWHcXRT0&feature=emb_logo&ab_channel=POWER4BIO",
          presentation: require("@/assets/files/webinars/food/webinar1.pdf")
            .default,
          embed: "https://www.youtube.com/embed/K-DQWHcXRT0"
        },
        {
          title: "Technical examples on added value generation",
          speakers: ["Marieke Bruins – Wageningen Food & Biobased Research"],
          // video:"https://www.youtube.com/watch?v=5kTkDVjaM-Y&feature=emb_logo&ab_channel=POWER4BIO",
          presentation: require("@/assets/files/webinars/food/webinar2.pdf")
            .default,
          embed: "https://www.youtube.com/embed/5kTkDVjaM-Y"
        },
        {
          title:
            "Examples connected to sustainability ambitions, upcycling and complete biomass valorisation",
          speakers: ["Marieke Bruins – Wageningen Food & Biobased Research"],
          // video:"https://www.youtube.com/watch?v=_iHITOUk0AQ&feature=emb_logo&ab_channel=POWER4BIO",
          presentation: require("@/assets/files/webinars/food/webinar3.pdf")
            .default,
          embed: "https://www.youtube.com/embed/_iHITOUk0AQ"
        },
        {
          title: "Examples connected to trends in food",
          speakers: ["Jan Broeze – Wageningen Food & Biobased Research"],
          organization: "Wageningen Food & Biobased Research",
          // video:"https://www.youtube.com/watch?v=D5Ee4zGkix4&feature=emb_logo&ab_channel=POWER4BIO",
          presentation: require("@/assets/files/webinars/food/webinar4.pdf")
            .default,
          embed: "https://www.youtube.com/embed/D5Ee4zGkix4"
        },
        {
          title: "Technical examples of regional initiatives",
          speakers: ["Marieke Bruins – Wageningen Food & Biobased Research"],
          // video:"https://www.youtube.com/watch?v=vvZlXXc7XP0&feature=emb_logo&ab_channel=POWER4BIO",
          presentation: require("@/assets/files/webinars/food/webinar5.pdf")
            .default,
          embed: "https://www.youtube.com/embed/vvZlXXc7XP0"
        },
        {
          title: "Learnings for high potential value chains",
          speakers: ["Jan Broeze – Wageningen Food & Biobased Research"],
          // video:"https://www.youtube.com/watch?v=TaiJNURwY2U&ab_channel=POWER4BIO",
          presentation: require("@/assets/files/webinars/food/webinar6.pdf")
            .default,
          embed: "https://www.youtube.com/embed/TaiJNURwY2U"
        },
        {
          title:
            "How to finance food and feed value chains using European Structural and Investment Funds",
          speakers: ["Mr. Luigi Amati – META"],
          // video:"https://www.youtube.com/watch?v=-7Lm3dNbQj0&feature=emb_logo&ab_channel=POWER4BIO",
          presentation: require("@/assets/files/webinars/food/webinar7.pdf")
            .default,
          embed: "https://www.youtube.com/embed/-7Lm3dNbQj0"
        },
        {
          title: "Supporting policies for bioeconomy – focus on food and feed",
          speakers: [
            "Dr. Berien Elbersen – Wageningen Food & Biobased Research"
          ],
          // video: "https://www.youtube.com/watch?v=fwtCq5ay7fk&feature=emb_logo&ab_channel=POWER4BIO",
          presentation: require("@/assets/files/webinars/food/webinar8.pdf")
            .default,
          embed: "https://www.youtube.com/embed/fwtCq5ay7fk"
        }
      ]
    };
  },
  computed: {
    webinarProps() {
      return {
        title: this.$t("title"),
        date: this.$t("date"),
        intro: this.$t("intro"),
        agenda: Object.values(this.$t("agenda")),
        videoLink: this.videoLink,
        presentationLink: this.videoLink
      };
    }
  }
};
</script>

<style lang="scss">
@import "@/styles/main";
.webinar {
  background-color: $off_white !important;
  .v-list {
    font-family: $font-family;
  }

  .img {
    width: auto;
    height: 400px;
  }
}
</style>
